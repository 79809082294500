<template>
  <el-dialog
    title="修改用户信息"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="用户">
        {{ this.modalData.realName }}
      </el-form-item>

      <el-form-item label="电话" prop="phone">
        <el-input
          v-model="this.modalData.phone"
          placeholder="请输入电话号码"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="微信openId" prop="openId">
        <el-input
          v-model="this.modalData.openId"
          placeholder="请输入微信openId"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="套餐时间" prop="endTime">
        <el-date-picker
          v-model="this.modalData.endTime"
          type="datetime"
          placeholder="套餐时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="this.modalData.remark"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 10 }"
          placeholder="请输入备注"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { validatorForm } from "@/common/util";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      ruleValidate: {
        phone:[{
          required: true,
          message: "请输入电话号码"
        }],
        openId:[{
          required: true,
          message: "openid 不能为空"
        }],
      },
    };
  },
  methods: {
    submit() {
      let modalData = this.modalData;

      let data = {
        userId: modalData.userId,
        endTime: modalData.endTime,
        phone: modalData.phone,
        remark: modalData.remark,
        openId: modalData.openId
      };

      this.validateForm().then((res) => {
        let obj = this.deepClone(data);
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
